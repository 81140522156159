<script setup>
import { onMounted, ref } from 'vue'
import request from '@/utils/request'

const { location } = defineProps(['location'])

const activitys = ref([])

async function getData() {
  const res = await request('/mall/getActivityList', { params: { location: location } })
  activitys.value = res.data
}

function handleGOActivity(item) {
  window.location.href = `/activity/h5?uid=${item.uid}`
}

onMounted(() => {
  getData()
})
</script>

<template>
  <div class="ac-22343">
    <div class="ac-item" v-for="(item, index) in activitys" :key="index" @click="handleGOActivity(item)">
      <img :src="item.icon" alt="" />
    </div>
  </div>
</template>

<style scoped lang="less">
.ac-22343 {
  width: 100%;
  .ac-item {
    width: 100%;
    margin-top: 12px;
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>
